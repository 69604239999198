import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'btnSlot',
    'nextLink',
    'nextLinkMobile',
    'nextBtn',
    'nextBtnMobile',
    'selectedDateSlot',
    'mobileSlides',
    'deskSlides',
    'chevronNextWeek',
    'chevronPrevWeek',
    'contactForm',
    'confirmationPage',
    'bottomContainer',
    'moreSlotsCta',
    'stickyContainer'
  ];

  static values = {
    uniqueHash: String,
    slotsCount: Number,
    visit: Object,
    user: Object,
  }

  connect() {
    this.handleGa4Tracking();
  }

  initialize() {
    if (this.hasNextLinkTarget) {
      this.originalNextUrl = atob(this.nextLinkTarget.dataset.encode);
    }
    this.btnSlotTargets.forEach((btn) => {
      btn.setAttribute('data-action', 'click->application--booking#handleBtnSlot');
    });
  }
  // pass slot to next url in btn
  handleBtnSlot = (e) => {
    const btn = e.currentTarget;
    // deactivate all slots
    [...this.btnSlotTargets].forEach((btn) => {
      btn.classList.remove('active');
    });
    // activate chosen slot
    btn.classList.add('active');
    // add chosen slot datetime to next page url
    this.nextLinkTarget.href = this.originalNextUrl + '&slot=' + encodeURIComponent(btn.dataset.slot) + '&slot_type=' + btn.dataset.type + '&source=' + this.nextLinkTarget.dataset.source + '&shared_by_user=' + this.nextLinkTarget.dataset.sharedByUser + '&lead_id=' + this.nextLinkTarget.dataset.lead;
    // enable or display button to next page (according to device)
    if (this.hasStickyContainerTarget) {
      this.stickyContainerTarget.classList.remove('vesta-display-none');
      setTimeout(() => {
        this.stickyContainerTarget.classList.remove('vesta-opacity-0');
        this.stickyContainerTarget.classList.add('vesta-opacity-1');
      }, 100);
    } else {
      this.nextLinkTarget.classList.remove('vesta-button-disabled');
    }

    this.selectedDateSlotTargets.forEach((dateSlot) => (dateSlot.innerText = this.localDateTime(Date.parse(btn.dataset.slot))));

    // Update day slots style if time slots selected in other day column
    let activeDaySlot = document.querySelector('.vesta-booking-day-slot.active');
    // return if in admin view
    if (!activeDaySlot) return;
    // Day slot value from time slot
    const daySlotValue = e.currentTarget.dataset.day;

    if (activeDaySlot.dataset.current_date === daySlotValue) return;

    activeDaySlot.classList.remove('active');
    const daySlot = document.querySelector(`[data-current_date="${daySlotValue}"].vesta-booking-day-slot`);
    daySlot.classList.add('active');
  };

  showMoreSlots = () => {
    const hiddenSlots = document.querySelectorAll('.vesta-booking-time-container.vesta-display-none');
    hiddenSlots.forEach((slot) => {
      slot.classList.remove('vesta-display-none');
      setTimeout(() => {
        slot.classList.add('vesta-opacity-1');
      }, 100);
    });

    if (this.hasBottomContainerTarget) {
      this.bottomContainerTarget.classList.add('vesta-sticky-container');
      this.bottomContainerTarget.classList.remove('vesta-position-relative');
    }

    if (this.hasMoreSlotsCtaTarget) {
      this.moreSlotsCtaTarget.classList.add('vesta-display-none');
    }
  }

  localDateTime(timeInt) {
    let event = new Date(timeInt);
    let options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    let strResult = event.toLocaleDateString('fr-FR', options);

    return strResult.charAt(0).toUpperCase() + strResult.slice(1);
  }

  manageSlots = (e) => {
    [...this.mobileSlidesTargets].forEach((slide) => {
      slide.classList.add('hidden');
      [...document.querySelectorAll('li')].forEach((li_element) => {
        if (li_element.dataset.current_date == e.currentTarget.dataset.date) {
          [...li_element.getElementsByClassName('hidden')].forEach((hidden_el) => {
            hidden_el.classList.remove('hidden');
          });
        }
      });
    });
  };

  managePrevWeek = () => {
    this.deskSlidesTargets.slice(0, 7).forEach((day) => {
      day.classList.remove('hidden-desk');
    });
    this.deskSlidesTargets.slice(7).forEach((day) => {
      day.classList.add('hidden-desk');
    });
    this.chevronPrevWeekTarget.classList.add('hidden');
    this.chevronNextWeekTarget.classList.remove('hidden');
  };

  manageNextWeek = () => {
    this.deskSlidesTargets.slice(0, 7).forEach((day) => {
      day.classList.add('hidden-desk');
    });
    this.deskSlidesTargets.slice(7).forEach((day) => {
      day.classList.remove('hidden-desk');
    });
    this.chevronPrevWeekTarget.classList.remove('hidden');
    this.chevronNextWeekTarget.classList.add('hidden');
  };

  handleGa4Tracking = () => {
    if(this.slotsCountValue) {
      // new GA4 tracking plan
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'view_visit_slots',
        'slotsCount': this.slotsCountValue,
        'unique_hash': this.uniqueHashValue
      })
    }

    if(this.hasContactFormTarget) {
      // new GA4 tracking plan
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'view_visit_contact',
        'unique_hash': this.uniqueHashValue
      })
    }

    if(this.hasConfirmationPageTarget) {
      // new GA4 tracking plan
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'book_visit',
        'visit_date': this.visitValue.start_time,
        'slot_type': this.visitValue.slot_type,
        'user_id': this.visitValue.user_id,
        'user_email': this.userValue.email,
        'user_phone': this.userValue.phone_number,
        'user_first_name': this.userValue.first_name,
        'user_last_name': this.userValue.last_name,
        'unique_hash': this.uniqueHashValue
      })
    }
  }
}
